<template>
    <div>
        <div class="divider divider--40"></div>
        <div class="profile__layout">
            <back-to-link :history-replace="true" />
            <div v-if="loading" class="loader-rounded"></div>
            <div class="divider divider--20"></div>
            <section class="search-section">
                <h2 class="find-tag__hader heading heading--md">
                    {{ title }}
                    <span v-if="totalCount > 0"
                        >({{ totalCount }}
                        {{ totalCount > 1 ? 'creations' : 'creation' }})</span
                    >
                </h2>
            </section>

            <main>
                <div v-if="assets" class="asset-net">
                    <div class="divider divider--40"></div>
                    <AssetsGrid :assets="shownAssets" :loading="loading" />
                    <template
                        v-if="shownAssets.length < assets.count"
                        class="asset-net"
                    >
                        <Pagination
                            :total-assets-count="assets.count"
                            :assets-shown="shownAssets.length"
                            :pagination="pagination"
                            @update:rowsPerPage="changeRowsPerPage"
                        />
                    </template>
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import AssetsGrid from '@/components/assetsTable/AssetsGrid.vue';
import Pagination from '@/components/common/Pagination';
import EventBus from '@/EventBus';

import {
    AUTHOR_PROFILE_PAGE_NAME,
    CREATIONS_PAGE_NAME,
    SUBSCRIPTIONS_PAGE_NAME,
} from '@/router/pageNameConstants';
import { AssetsResource } from '@/services/assets';

import { UserResource } from '@/services/user';
import { mapGetters } from 'vuex';

export default {
    name: 'ProfileIndex',
    components: {
        AssetsGrid,
        Pagination,
    },
    data: function () {
        return {
            loading: true,
            assets: null,
            title: null,
            CREATIONS_PAGE_NAME,
            SUBSCRIPTIONS_PAGE_NAME,
            AUTHOR_PROFILE_PAGE_NAME,
            shownAssets: [],
        };
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLogged',
        }),
        ...mapGetters('settings', {
            mobile: 'isMobile',
            iOS: 'isIOS',
        }),
        pagination() {
            let query = this.$route.query;
            let params = {
                content_type: 3,
                limit: 30,
                offset: 0,
                order_direction: ['desc'],
                order_fields: ['subscribers_count'],
                tags: [],
                platform_compatibility: this.mobile || this.iOS ? 1 : 0,
            };

            if (Object.keys(query).length > 0) {
                if (query.limit) {
                    params.limit = parseInt(query.limit);
                }
                if (query.offset) {
                    params.offset = parseInt(query.offset);
                }
                if (query.order_fields) {
                    if (query.order_fields instanceof Array) {
                        params.order_fields = query.order_fields;
                    } else {
                        params.order_fields = Array(query.order_fields);
                    }
                }
                if (query.order_direction) {
                    if (query.order_direction instanceof Array) {
                        params.order_direction = query.order_direction;
                    } else {
                        params.order_direction = Array(query.order_direction);
                    }
                }
                if (query.main) {
                    params.main = isNaN(query.main)
                        ? query.main
                        : parseInt(query.main);
                }
                if (query.text) {
                    params.text = query.text;
                }
                if (query.tags) {
                    params.tags =
                        typeof query.tags === 'string'
                            ? query.tags.split('.')
                            : query.tags;
                }
                if (query.author) {
                    params.author = query.author;
                }
            }
            return params;
        },
        totalCount() {
            return this.assets ? this.assets.count : null;
        },
        pageName() {
            return this.$route.name;
        },
    },
    watch: {
        pagination() {
            this.loadAssets();
        },
    },
    created: function () {
        this.loadAssets();
        if (this.pageName) {
            EventBus.$on('AssetUnsubscribe', this.onUnsubscribe);
        }
    },
    destroyed: function () {
        EventBus.$off('AssetUnsubscribe', this.onUnsubscribe);
    },
    methods: {
        loadSubscriptions() {
            this.shownAssets = [];
            this.title = 'Your subscriptions';
            this.loading = true;
            UserResource.profile({ getSubscriptions: true }).then(
                (response) => {
                    this.assets = response.data.profile.subscriptions;
                    this.assets.forEach((element) => {
                        this.shownAssets.push(element);
                    });
                    this.loading = false;
                    // if (!(this.assets && this.assets.length > 0))
                    //     this.title = 'No subscriptions';
                }
            );
        },
        loadCreations() {
            this.title = 'Your creations';
            this.loading = true;
            UserResource.assets(this.pagination).then((response) => {
                this.assets = response.data;
                this.assets.rows.forEach((element) => {
                    this.shownAssets.push(element);
                });
                this.loading = false;
                if (!(this.assets.rows && this.assets.rows.length > 0))
                    this.title = 'No creations';
            });
        },
        loadAuthorAssets() {
            this.shownAssets = [];
            this.title = 'Authors Creations';
            this.loading = true;
            this.pagination.author = this.$route.params.id;
            AssetsResource.get(this.pagination).then((response) => {
                this.assets = response.data;
                this.assets.rows.forEach((element) => {
                    this.shownAssets.push(element);
                });
                EventBus.$emit('authorAssetsLoaded', this.assets);
                this.loading = false;
                if (!(this.assets.rows && this.assets.rows.length > 0))
                    this.title = 'No Authors Creations';
            });
        },
        updateUrl() {
            this.$router.push({ query: this.pagination });
        },
        changeRowsPerPage(num) {
            this.pagination.rowsPerPage = num;
            this.pagination.page = 1;
            this.updateUrl();
        },
        loadAssets() {
            this.assets = null;

            switch (this.pageName) {
                case CREATIONS_PAGE_NAME:
                    this.loadCreations(this.pagination);
                    break;
                case SUBSCRIPTIONS_PAGE_NAME:
                    this.loadSubscriptions();
                    break;
                case AUTHOR_PROFILE_PAGE_NAME:
                    this.loadAuthorAssets(this.pagination);
                    break;
            }
        },
        onUnsubscribe(id) {
            const { count, limit, rows } = this.assets;

            // If user has less then one page of subscriptions,
            // manually mutate subscription object
            if (count <= limit) {
                const newRows = rows.filter((asset) => asset.id !== id);
                const newCount = count - 1;

                this.assets = {
                    ...this.assets,
                    ...{
                        count: newCount,
                        rows: newRows,
                    },
                };
            } else {
                this.loadSubscriptions(this.pagination);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.search-section {
    padding: 0 0 2em;
    font-size: 1rem;

    @media (max-width: $sm-down) {
        padding: 1.5em 0;
    }
}

.profile__layout {
    margin: 0 auto;
    max-width: 1658px;
    width: 100%;
    min-height: 0;
}
</style>
